import { HeaderFooterParams } from '../types';
import { HttpClient } from '@wix/http-client';

enum ApiName {
  content,
}

interface UrlEnvMap {
  production: string;
  test: string;
}

const URL_MAP: { [key in ApiName]: UrlEnvMap } = {
  [ApiName.content]: {
    production: 'https://www.wix.com/_api/header-footer-service/content',
    test: '/header-footer-service',
  },
};

function generateQueryParams(params: Object) {
  const urlSearchParams = new URLSearchParams();
  Object.entries(params).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach(el => {
        urlSearchParams.append(key, el);
      });
    } else {
      urlSearchParams.append(key, value);
    }
  });
  const queryString = urlSearchParams.toString();
  return queryString ? `?${queryString}` : '';
}

function getUrl(apiName: ApiName, params: Object): string {
  const baseUrl = window && window.HEADER_FOOTER_PROVIDER_DEBUG ? URL_MAP[apiName].test : URL_MAP[apiName].production;
  const queryString = generateQueryParams(params);
  return baseUrl + queryString;
}

export async function getHeaderFooterContent(params: HeaderFooterParams) {
  const url = getUrl(ApiName.content, params);
  const httpClient = new HttpClient();
  const res = await httpClient.get(url);
  return res.data;
}
