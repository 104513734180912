export function convertStringToElements(str: string) {
  const headElements = Array.from(stringToHTMLElements(str));

  const fragment = document.createDocumentFragment();
  for (const element of headElements) {
    if (element.tagName === 'SCRIPT') {
      const newScript = document.createElement('script');
      Array.from(element.attributes).forEach(({ name, value }) => {
        newScript.setAttribute(name, value);
      });
      newScript.textContent = element.innerHTML;
      fragment.append(newScript);
    } else {
      fragment.append(element);
    }
  }
  return fragment;
}

function stringToHTMLElements(html: string) {
  const template = document.createElement('template');
  template.innerHTML = html;
  return template.content.children;
}
