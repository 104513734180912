import { HeaderFooterParams } from '../types';
import { convertStringToElements } from './converter';
import * as fetch from './fetch';

export async function initHeaderFooter(
  params: HeaderFooterParams = {},
  nodes: { header?: Element; footer?: Element } = {},
) {
  const { head, bodyBottom, bodyTop } = await fetch.getHeaderFooterContent(params);

  document.head.append(convertStringToElements(head));

  const { header, footer } = nodes;
  if (header) {
    header.innerHTML = bodyTop;
  } else {
    document.body.prepend(convertStringToElements(bodyTop));
  }

  if (footer) {
    footer.innerHTML = bodyBottom;
  } else {
    document.body.append(convertStringToElements(bodyBottom));
  }
}
